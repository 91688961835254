@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  

dl {
    display: flex;
    flex-direction: column;
    gap: 0.5rem 0;
    margin: 0;
    padding: 0;
    width: 100%;

    @include adm.breakpoint("tablet") {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @media print {
        flex-direction: row;
        flex-wrap: wrap;
    }
    dt {
        margin: 0;
        padding: 0;
        width: 100%;

        @include adm.breakpoint("tablet") {
            width: 40%;
        }
        @media print {
            width: 40%;
        }
        
    }
    dd {
        font-weight: 700;
        width: 100%;
        margin: 0;
        padding: 0 0 0.5rem;
        border-bottom: 1px solid adm.color("grey");
        @include adm.breakpoint("tablet") {
            width: 60%;
            padding: 0;
            border: none;
        }
        @media print {
            width: 60%;
            padding: 0;
            border: none;
        }
    }
}
