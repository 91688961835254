@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

.back-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: none;

    @include adm.breakpoint("tablet") {
        display: block;
    }

}