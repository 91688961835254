@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm; 

aside {
    display: none;

    @include adm.breakpoint("tablet") {
        display: flex !important;
        @include adm.grid-col(5);
    }
}

.steps-list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    &::before {
        content: '';
        width: 2px;

        background: adm.color("grey");
        display: block;
        position: absolute;
        top: 1.5rem;
        left: 1.9rem;
        bottom: 1.5rem;
    }

    .step {
        margin-bottom: 2rem;
        @include adm.breakpoint("tablet") {
            margin-bottom: 4rem;
        }
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 2rem;

        &::before {
            content: '';
            transform: scale(0.6);
            display: block;
            width: 4rem;
            height: 4rem;
            background: adm.theme-color("primary-light");
            border-radius: 50%;
            transition: all 0.2s ease-in-out;
        }
        &::after {
            content: '';
            display: block;
            width: 4rem;
            height: 4rem;
            background: transparent;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            @include adm.icon("check", #6CA5DA, 2rem); 
            opacity: 0;
        }

        &.is-active,
        &.is-valid {
            &::before {
                transform: scale(0.8);
                @include adm.breakpoint("tablet") {
                    transform: scale(1)
                }
            }
        }
        &.is-active {
            font-weight: bold;
            &::before {
                background: #000;
            }
        }
        &.is-valid {
            &::before {
                border: 2px solid #000;
                background: #fff;
            }
            &::after {
                opacity: 1;
            }
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}