@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

header {
    @include adm.edge-padding();
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 10000;

    @media print {
        box-shadow: none;
    }

    .inner {
        @include adm.container();
        @include adm.grid("1fr auto");
        padding-top: 1rem;
        padding-bottom: 1rem;
        align-items: center;
        position: relative;
        z-index: 2;

        @include adm.breakpoint("tablet") {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    .mobile-toggle {
        border: none;
        width: 3rem;
        height: 3rem;
        background: none;
        opacity: 0.6;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 1;
        }

        @include adm.breakpoint("tablet") {
            display: none;
        }
    }

    nav {
        display: flex;
        gap: 2rem;
        align-items: center;

        @media print {
            display: none;
        }
    }

    .logo {
        width: 4rem;

        @include adm.breakpoint("tablet") {
            width: 28rem;
        }

        @media print {
            width: 28rem;
        }

        svg {
            height: auto;
            width: 100%;
        }

        .mobile-logo {
            display: block;

            @include adm.breakpoint("tablet") {
                display: none;
            }

            @media print {
                display: none;
            }
        }

        .full-logo {
            display: none;

            @include adm.breakpoint("tablet") {
                display: block;
            }

            @media print {
                display: block;
            }
        }


    }

}


.language-select__control {
    border: none !important;
    padding: 0 !important;

    height: 3.2rem !important;
    min-height: 0 !important;

    &.language-select__control--is-focused {

        outline: 1px solid #000 !important;
        box-shadow: none;
    }

    .language-select__value-container {
        padding: 0 !important;

    }

    .language-select__value {
        line-height: 1 !important;
    }

    .language-select__indicators {

        .language-select__indicator-separator {
            display: none;
        }
    }

    .language-select__dropdown-indicator {
        padding: 0px 8px;
        color: #000;
    }

}

.language-select__menu {
    width: max-content !important;

    .language-select__option {
        padding: 1rem 2rem;
        border-bottom: 1px solid adm.color("grey-light");
    }
}

.language-select__option--is-focused {
    background: adm.color("grey-light") !important;
}

.language-select__option--is-selected {

    &.language-select__option--is-focused {
        background: adm.theme-color("primary-dark") !important;
    }

    background: adm.theme-color("primary") !important;
}

.language-select__option--is-hovered {
    background: blue !important;
}