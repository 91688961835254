@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  

.info {
    background: adm.theme-color("primary-dark");
    color: #fff;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include adm.edge-padding();

    @media print {
        display: none;
    }

    p {
        margin: 0;
    }

    h2 {
        margin-bottom: 1rem;
    }
}