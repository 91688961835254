@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  

.print-info {
    border: 1px solid adm.color("grey");
    padding: 2rem;
    display: flex;
    align-items: center;
    background: adm.color("grey-lighter");

    .adm-button {
        margin-left: auto;
    }

    @media print {
        display: none;
    }
}

@media print {
    body {
        background: #fff;
        color: #000;
    }

    .help,
    .error-box,
    .blue-box {
        display: none;
    }
}
