@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  


.section {
    
    .inner {
        padding: adm.space(small) 0;
    
        @include adm.breakpoint("tablet") {
            padding: adm.space(large) 0;
        }
        @include adm.breakpoint("desktop") {
            padding: adm.space(large) 0;
        }

    }

    .center {
        text-align: center;
    }
}