@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  


.blue-box {
    background: adm.theme-color("primary-lighter");
    padding: adm.space(small);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    .adm-button {
        margin-left: auto;
    }

    p {
        margin: 0;
        padding: 0;
    }

    h2 {
        max-width: 60rem;
        margin: 0 auto;
        text-align: center;
    }
    
} 