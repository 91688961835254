@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

.form-layout {

    @include adm.edge-padding();

    .inner {
        @include adm.grid();
        gap: 0;
        padding: adm.space(large) 0;
    }



    section {


        @include adm.grid-col(12);

        @include adm.breakpoint("tablet") {
            @include adm.grid-col(7);
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
        }
    }

}

.intro {
    @include adm.grid-col(12);
    @include adm.type("h2");
    background: adm.color("grey-lighter");
    text-align: center;
    margin-top: -3rem;
    margin-bottom: adm.space("large");
    padding: adm.space() 2rem;
}




.adm-stack {

    &.long-short {
        flex-direction: column;
    }

    &.short-long {
        flex-direction: column;
    }

    @include adm.breakpoint("tablet") {
        &.long-short {
            flex-direction: row;

            > :nth-child(2) {
                width: 20rem;
                flex-shrink: 0;
            }
        }

        &.short-long {
            flex-direction: row;

            > :nth-child(1) {
                width: 20rem;
                flex-shrink: 0;
            }
        }
    }
}


.adm-checkbox {
    padding-left: 3rem;
    position: relative;

    input[type="checkbox"] {
        font-size: 4rem;
        left: 0;
        top: 0.5rem;
        margin-right: 0;
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
    }

    .helper {
        display: block;
        color: adm.theme-color("feedback-error");
    }
}


.react-datepicker-wrapper {
    width: 6rem !important;
    order: 2;

    &:focus {
        //background: green;
    }
}

.react-datepicker__input-container {
    &:focus {
        //background: yellow;
    }
}


.adm-formfield {

    .adm-formfield-label {
        display: flex;
    }

    label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    [disabled] {
        opacity: 0.36;
    }

    [readonly] {
        opacity: 0.6;
    }
}

.select,
.autocomplete {
    order: 2;

    .select__value-container {
        padding: 0 2rem;
        white-space: nowrap;
    }

    .select__dropdown-indicator {
        padding: 0;
        height: 6rem;
        width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
    }

    .select__indicator-separator {
        display: none;
    }

    .select__input-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    .select__control {
        height: 6rem;
        border-color: adm.color("grey-dark");

        &:hover {
            border-color: adm.color("grey-dark") !important;
        }
    }

    .select__control--is-focused {

        outline: 2px solid #000 !important;
        box-shadow: none !important;
        border-color: #000 !important;
        transition: none !important;
    }

    .select__menu {
        margin: 0;

        .select__option {
            padding: 2rem;
            border-bottom: 1px solid adm.color("grey");
        }

        .select__option--is-focused {
            background: adm.color("grey-light") !important;
        }

        .select__option--is-selected {

            &.select__option--is-focused {
                background: adm.theme-color("primary-dark") !important;
            }

            background: adm.theme-color("primary") !important;
        }
    }

    // invalid states
    .is-invalid & {


        .select__control {

            border-color: adm.theme-color("forms-border-invalid") !important;
        }

        .select__control--is-focused {
            outline: 2px solid adm.theme-color("forms-border-invalid") !important;
        }
    }

}

.autocomplete {
    .select__indicators {
        display: none !important;
    }
}


.autocomplete-list {
    top: 100%;
    width: 100%;
    padding: 0;
    z-index: 3;
    margin: 0;
    max-height: 30rem;
    box-sizing: border-box;
    margin-top: 2px;


    @include adm.breakpoint("tablet") {
        min-width: 100%;
        width: max-content;
        max-width: 90vw;
    }

    &:not(:empty) {
        border: 1px solid adm.color("grey");
    }

    li {
        margin: 0;
        padding: 2rem;
        border-bottom: 1px solid adm.color("grey-light");
    }
}

