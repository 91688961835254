@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  

/* aus footer importiert... */


footer {
    @include adm.edge-padding();
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
    background: #fff;
    display: none;

    @include adm.breakpoint("tablet") {
        display: block;
    }
    
    .inner {
        @include adm.container();
        padding-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        align-items: center;
        gap: 5rem;
    }

    nav {
        display: flex;
        align-items: center;
        gap: 5rem;
        
        a {
            @include adm.type("flow-small");
            color: adm.theme-color("text");
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        
    }
    p {
        @include adm.type("flow-small"); 
    }

    
}