@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  


body {
    background: adm.theme-color("background");
    color: adm.theme-color("text");

    &.mobile-menu-open {
        overflow: hidden;
    }
}

.docu {
    @include adm.container();
    padding-top: adm.space();

    .doc-label {
        color: adm.theme-color("primary");
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 2rem;
        margin-top: 10rem;
        display: block;

        &:first-child {
            margin-top: 0;
        }
    }

    .color-square {
        width: 10rem;
        height: 10rem;
    }
}

hr {
    width: 100%;
    border: none;
    margin: adm.space(small) 0;
    border-bottom: 1px solid adm.color("grey-dark");
}

.debugger {
    textarea{
        width: 100%;
        min-height: 30rem;
        font-size: 1.4rem;
        line-height: 1.2;
    }
}