@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  


.mobile-menu {
    position: fixed;
    -webkit-overflow-scrolling: smooth;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: adm.color("grey-lightest");
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-top: 10rem;
    display: none;

    @include adm.breakpoint("tablet") {
        display: none !important;
    }

    .mobile-menu-open & {
        display: flex;
    }

    aside {
        padding: 0 adm.space("small");
        display: flex !important;
    }

    .mobile-footer-nav {
        background: #fff;
        padding: adm.space("small") adm.space();
        flex-grow: 1;
        
        nav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }
    }
}