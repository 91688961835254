@use "/src/styles/config" as adm-cfg;
@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;  

.help {
    background: adm.theme-color("primary-lighter");
    display: flex;
    flex-direction: column;
    position: relative;
    @include adm.edge-padding();

    @media print {
        display: none;
    }

    .head {
        button {
            width: 100%;
            border: none;
            background: transparent;
            padding: 2rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 1rem;
            @include adm.type("h3");
            margin-bottom: 0;        
            cursor: pointer;
        }
    }

    .body {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 10;
        background: adm.theme-color("primary-lighter");
        display: none;
        padding-bottom: adm.edge-padding();
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);

        @include adm.edge-padding();

        .box {
            max-width: 62rem;
            margin: 0 auto;
            background: #fff;
            padding: 4rem;
            border: 1px solid #ccc;
            display: flex;
            flex-direction: column;
            gap: 4rem;
        }

        .contact-option {
            padding: 2rem;
            border: 1px solid #ccc;
            text-align: center;

            a {
                .adm-icon {
                    margin-right: 1rem;
                }
            }
        }

        .adm-button {
            display: flex;
            margin: adm.space("smaller") auto 0;
        }
    }

    &.is-open {
        .head .adm-icon {
            transform: rotate(-180deg);
        }
        .body {
            display: block;
        }
    }


}